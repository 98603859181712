<template>
  <div class="div-history-evaluation">
    <el-table
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column
        prop="date"
        label="Fecha"
        width="100"
      />
      <el-table-column
        prop="time"
        label="Hora"
        width="70"
      />
      <el-table-column
        prop="type"
        label="Tipo"
      />
      <el-table-column label="Creador">
        <template slot-scope="scope">
          <label>{{tableData[scope.$index].creator.firstName}}&nbsp;</label>
          <label>{{tableData[scope.$index].creator.lastName}}</label>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Estado"
      >
        <template slot-scope="scope">
          <el-tag
            v-if="tableData[scope.$index].canceled === true"
            type="danger"
          >CANCELADA</el-tag>
          <el-tag
            v-if="tableData[scope.$index].canceled === 'Evaluado'"
            type="success"
          >{{tableData[scope.$index].canceled}}</el-tag>
          <el-tag
            v-if="tableData[scope.$index].canceled === 'Canceló'"
            type="warning"
          >{{tableData[scope.$index].canceled}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Operación"
        v-if="getRole() === 'TS' || getRole() === 'ADMIN'"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="rowSelect(scope.$index, scope.row)"
          >Visualizar</el-button>
      </template>
    </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    patientId: {
      type: Number,
    },
  },
  data() {
    return {
      idpatient: '',
      idApointment: '',
      tableData: [],
      appointmentData: '',
    };
  },
  methods: {
    rowSelect(row) {
      const { userId, patientId } = this.tableData[row];
      const social = { userId, patientId };
      localStorage.setItem('appointmentsTypeSelected', JSON.stringify(social));
      this.idApointment = this.tableData[row].id;
      this.$router.push(`/admin/evaluations/SocialHistory/${this.idpatient}/${this.idApointment}`);
    },
    async getAppointments() {
      try {
        const response = await this.axios.get(`patient/appointment/social/history/${this.patientId}`);
        const { data } = response;
        if (data) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].user == null) {
              data[i].user = 'desconocido';
            }
            if (data[i].checked === 0 && data[i].canceled === 0) {
              data[i].status = 'No se presentó';
            } else if (data[i].checked === 1 && data[i].canceled === 0) {
              data[i].status = 'Evaluado';
            } else if (data[i].checked === 0 && data[i].canceled === 1) {
              data[i].status = 'Canceló';
            }
          }
          this.tableData = data;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    getRole() {
      const localAuth = localStorage.getItem('vuex');
      const user = JSON.parse(localAuth);
      return user.auth.user.roleId;
    },
  },
  mounted() {
    this.getAppointments();
  },
  created() {
    this.idpatient = this.patientId;
  },
  watch: {
    patientId(val) {
      if (val) {
        this.getAppointments();
      }
    },
  },
};
</script>
