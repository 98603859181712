<!-- eslint-disable max-len -->
<template>
  <div class="div-history-evaluation">
    <el-table
      v-if="tableDataOfta.length > 0 && type === 'OFTA'"
      class="table-history"
      :data="tableDataOfta"
      style="width: 100%;"
    >
      <el-table-column
        label="Atendido por"
        prop="evaluator"
      />
      <el-table-column
        label="Estado"
        prop="estatus"
      />
      <el-table-column
        prop="subject"
        label="Agenda"
      />
      <el-table-column
        prop="evaluationDate"
        label="Fecha de evaluación"
      />
      <el-table-column
        v-if="findPermission(['FD', 'TS', 'ADMIN'])"
        label="Agendado por"
        prop="creator"
      />
      <el-table-column
        label="Operación"
        >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="tableDataOfta[scope.$index].estatus === 'No asistió' ? 'Sin información Oftalmologica' : 'Visualizar'"
            placement="top"
          >
            <el-button
              v-if="!tableDataOfta[scope.$index].canceled"
              :disabled="tableDataOfta[scope.$index].estatus === 'No asistió'"
              type="primary"
              size="mini"
              plain
              icon="el-icon-view"
              @click="rowSelect(scope.$index, scope.row, 'view')"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Evaluar"
            placement="top"
          >
            <el-button
              v-if="!tableDataOfta[scope.$index].canceled"
              type="primary"
              size="mini"
              plain
              icon="el-icon-edit"
              @click="rowSelect(scope.$index, scope.row, 'evaluate')"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Ver estudios de gabinete"
            placement="top"
          >
            <el-button
              v-if="!tableDataOfta[scope.$index].canceled && tableDataOfta[scope.$index].subject === 'Gabinete'"
              type="primary"
              size="mini"
              plain
              icon="el-icon-view"
              @click="rowSelectGabinete(scope.$index, scope.row)"
            >
              Gabinete
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-if="tableDataOpto.length > 0 && type === 'OPTO'"
      class="table-history"
      :data="tableDataOpto"
      style="width: 100%;"
    >
      <el-table-column
        label="Atendido por"
        prop="evaluator"
      />
      <el-table-column
        label="Estado"
        prop="estatus"
      />
      <el-table-column
        prop="subject"
        label="Agenda"
      />
      <el-table-column
        prop="evaluationDate"
        label="Fecha de evaluación"
      />
      <!-- <el-table-column
        label="Tipo cita"
        prop="type"
      /> -->
      <el-table-column label="Operación">
        <template slot-scope="scope">
          <el-tooltip
            v-if="!tableDataOpto[scope.$index].canceled"
            :content="tableDataOpto[scope.$index].estatus === 'No asistió' ? 'Sin información Optometrica' : 'Visualizar'"
            class="item"
            effect="dark"
            placement="top"
          >
            <el-button
              :disabled="tableDataOpto[scope.$index].estatus === 'No asistió'"
              type="primary"
              size="mini"
              plain
              icon="el-icon-view"
              @click="rowSelect(scope.$index, scope.row, 'view')"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Evaluar"
            placement="top"
          >
            <el-button
              v-if="!tableDataOpto[scope.$index].canceled"
              type="primary"
              size="mini"
              plain
              icon="el-icon-edit"
              @click="rowSelect(scope.$index, scope.row, 'evaluate')"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Ver estudios de gabinete"
            placement="top"
          >
            <el-button
              v-if="!tableDataOpto[scope.$index].canceled && tableDataOpto[scope.$index].subject === 'Gabinete'"
              type="primary"
              size="mini"
              plain
              icon="el-icon-view"
              @click="rowSelectGabinete(scope.$index, scope.row)"
            >
              Gabinete
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="showAppointmentGabinete"
      width="70%"
      append-to-body
    >
    <div style="padding: 20px;">
      <h3>Estudios de gabinete</h3>
      <el-table :data="appointments" style="margin:20px 0 20px 0;">
        <el-table-column prop="estudioName" label="Estudio" />
        <el-table-column prop="procedimientoName" label="Procedimiento" />
        <el-table-column prop="equipoName" label="Equipo" />
        <el-table-column prop="eyeSide" label="Ojo" />
        <el-table-column v-if="!showAppointmentGabinete" fixed="right" label="Operaciones">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              type="text"
              size="small">
              Eliminar
              <i class="el-icon-delete" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import store from '../../store';
import router from '../../router';

export default {
  props: {
    patientId: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      appointmentId: '',
      especialidades: [],
      userTypeId: '',
      tableDataOfta: [],
      tableDataOpto: [],
      showAppointmentGabinete: false,
      appointments: [],
    };
  },
  methods: {
    getRole() {
      try {
        const { user } = store.state.auth;
        return Object.keys(user).length > 0 ? user.roleId : false;
      } catch (error) {
        return false;
      }
    },
    findPermission(rolesPermission) {
      return rolesPermission.includes(this.userTypeId);
    },
    rowSelect(row, type, action) {
      if (action === 'evaluate') {
        this.appointmentId = this.tableData[row].id;
        if (this.userTypeId === 'MD' && this.tableData[row].subject !== 'Gabinete') {
          router.push(`/admin/evaluations/${this.type}/${this.patientId}/${this.appointmentId}`);
        } else if (this.userTypeId === 'OPTO' || this.tableData[row].subject === 'Gabinete') {
          router.push(`/admin/evaluations/opto/${this.patientId}/${this.appointmentId}`);
        }
      } else if (action === 'view') {
        this.appointmentId = this.tableData[row].id;
        router.push(`/admin/evaluations/oftahistoryNuevo/${this.patientId}/${this.appointmentId}`);
      }
    },
    async rowSelectGabinete(row) {
      this.appointmentId = this.tableData[row].id;
      await this.getAppointmentsGabinete(this.appointmentId);
    },
    orderByDateAndTime(data) {
      this.tableData = data.sort((a, b) => {
        const dateA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm');
        const dateB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm');
        return dateB - dateA;
      });
    },
    async getAppointments() {
      try {
        const request = await this.axios.get(this.getEvaluation());
        const { data } = request;
        if (data && this.type === 'OFTA') {
          data.forEach((element) => {
            const obj = {
              id: element.id,
              date: element.date.split('T')[0],
              time: element.time,
              subject: element.subject,
              evaluator: element?.evaluator?.id ? element.evaluator.name : 'N/A',
              evaluationDate: element.evaluationDate ? element.evaluationDate.split('T')[0] : 'N/A',
              creator: element?.creator?.name || '',
              estatus: this.getEstado({ data: element }),
              canceled: element.canceled,
            };
            this.tableDataOfta.push(obj);
          });
          this.orderByDateAndTime(this.tableDataOfta);
        } else if (data && this.type === 'OPTO') {
          data.forEach((element) => {
            const obj = {
              id: element.id,
              date: element.date.split('T')[0],
              time: element.time,
              subject: element.subject,
              evaluator: element?.evaluator?.id ? element.evaluator.name : 'N/A',
              evaluationDate: element.evaluationDate ? element.evaluationDate.split('T')[0] : 'N/A',
              creator: element?.creator?.name || '',
              estatus: this.getEstado({ data: element }),
              canceled: element.canceled,
            };
            this.tableDataOpto.push(obj);
          });
          this.orderByDateAndTime(this.tableDataOpto);
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async getAppointmentsGabinete(citaId) {
      try {
        const request = await this.axios.get(`patient/gabineteAppointment/${citaId}`);
        const { data } = request;

        if (data) {
          const dataParsed = [];

          data.forEach((element) => {
            dataParsed.push({
              estudioName: element?.gabinete.name || 'N/A',
              equipoName: element?.equipo === null ? 'N/A' : element?.equipo.name,
              procedimientoName: element.gabineteTypeProcedure?.name || 'N/A',
              procedureTypesName: element?.gabineteTypeProcedure?.name || 'N/A',
              eyeSide: element.eyeSide,
            });
          });

          this.appointments = dataParsed;
          this.showAppointmentGabinete = true;
        }
      } catch (error) {
        //
      }
    },
    getEvaluation() {
      const objFunction = {
        OFTA: `/patient/ophtha/oftaProfile/${this.patientId}`,
        OPTO: `/patient/opto/optoProfile/${this.patientId}`,
        TS: 'ts',
      };

      return objFunction[this.type];
    },
    getEstado({ data }) {
      const evaluatorId = data?.evaluator?.id || null;

      if (evaluatorId) {
        return 'Evaluado';
      }
      const options = {
        timeZone: 'America/Tijuana',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };

      const tijuanaTime = new Intl.DateTimeFormat('en-US', options).format(new Date());
      const { canceled, checked } = data;
      const actualDate = new Date().toISOString().split('T')[0];

      if (!canceled) {
        if ((data.date <= actualDate && data.time <= tijuanaTime) && !checked) {
          return 'No asistió';
        }
      }
      if (canceled) {
        return `Cancelado: ${data.cancelReason}`;
      }
      return 'Pendiente';
    },
  },
  async created() {
    this.userTypeId = this.getRole();
    this.especialidades = JSON.parse(localStorage.getItem('especialidades'));
    await this.getAppointments();
  },
  watch: {
    patientId(val) {
      if (val) {
        this.tableDataOfta = [];
        this.tableDataOpto = [];
        this.getAppointments();
      }
    },
  },
};
</script>
<style lang="scss">
@import './styles/history.scss';
</style>
